import React, { createContext, useEffect, useState } from 'react';
import { IsTokenOwnerContextValueContext } from '../../../../../types';
import { useQuery } from '@apollo/react-hooks';
import { useMoralis } from 'react-moralis';
import IsTokenOwnerQuery from '../../../../api/isTokenOwner.query.gql';
import {
    AspectRatio,
    Flex,
    Heading,
    Image,
    ScaleFade,
    useMediaQuery,
} from '@chakra-ui/react';
import { navigate } from 'gatsby';
import {
    QUERY_PARAM_ACCESS_DENIED,
    QUERY_PARAM_NOT_AUTHENTICATED,
} from '../../../pages/mint';
import G3LogoSpinner from '../../general/G3LogoSpinner';
import Layout from '../../base/Layout';
import G3ALPHAPNG from '../../../../assets/G3-ALPHA.png';
import G3ALPHAWebM from '../../../../assets/G3-ALPHA.webm';

export const G3TokenGateContext = createContext({});

const G3TokenGateProvider = ({ children }: { children: React.ReactNode }) => {
    const {
        isInitialized,
        isInitializing,
        isAuthenticated,
        user,
        isAuthenticating,
    } = useMoralis();
    const ethAddress = user?.get('ethAddress');

    const { data, loading } = useQuery(IsTokenOwnerQuery, {
        variables: {
            walletAddress: ethAddress,
            chain: process.env.GATSBY_NFT_CHAIN,
            tokenAddress: process.env.GATSBY_NFT_ALPHA_ADDRESS,
        },
        skip: !isAuthenticated || !(ethAddress?.length > 0),
        fetchPolicy: 'network-only',
    });

    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const [hasAccess, setHasAccess] = useState(null as null | false | true);
    const [videoError, setVideoError] = useState(false);

    useEffect(() => {
        if (!loading) {
            const isOwner = data?.isTokenOwner?.data?.owner;
            setHasAccess(isOwner);

            if (isOwner === false) {
                navigate(`/mint?${QUERY_PARAM_ACCESS_DENIED}=1`);
            }
        }
    }, [data, loading]);

    useEffect(() => {
        if (
            !isInitializing &&
            isInitialized &&
            !isAuthenticating &&
            !isAuthenticated
        ) {
            navigate(`/mint?${QUERY_PARAM_NOT_AUTHENTICATED}=1`);
        }
    }, [isInitialized, isInitializing, isAuthenticating, isAuthenticated]);

    return (
        <G3TokenGateContext.Provider
            value={
                {
                    hasAccess,
                } as IsTokenOwnerContextValueContext
            }
        >
            {hasAccess === false && (
                <Layout noHeader>
                    <Flex />
                </Layout>
            )}

            {hasAccess === null && (
                <Layout noHeader>
                    <Flex
                        direction="column"
                        height="100vh"
                        align="center"
                        justify="center"
                    >
                        <AspectRatio
                            maxW={['80vw', '30vw']}
                            ratio={1}
                            w="100%"
                            overflow="hidden"
                        >
                            <ScaleFade in style={{ height: '100%' }}>
                                {videoError || isMobile ? (
                                    <Image width={'100%'} src={G3ALPHAPNG} />
                                ) : (
                                    <video
                                        height={'100%'}
                                        width={'100%'}
                                        controls={false}
                                        autoPlay
                                        muted
                                        loop
                                        onError={() => setVideoError(true)}
                                        playsInline
                                    >
                                        <source
                                            src={G3ALPHAWebM}
                                            type="video/webm"
                                        />
                                    </video>
                                )}
                            </ScaleFade>
                        </AspectRatio>
                        <Heading
                            size={isMobile ? 'sm' : 'md'}
                            textAlign={['center', 'left']}
                            margin="0 1rem"
                        >
                            Verifying Genv3rse ALPHA Pass NFT ownership...
                        </Heading>
                        <G3LogoSpinner containerProps={{ h: '20vh' }} />
                    </Flex>
                </Layout>
            )}
            {/*{hasAccess === false && <Text>you need token</Text>}*/}
            {hasAccess === true && children}
        </G3TokenGateContext.Provider>
    );
};

export default G3TokenGateProvider;
