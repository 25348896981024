import { useMoralis } from 'react-moralis';

export const ALPHA = 'ALPHA';

const contracts = {
    [ALPHA]: {
        contractAddress: process.env.GATSBY_NFT_ALPHA_ADDRESS,
        abi: process.env.GATSBY_NFT_ALPHA_ABI,
    },
};

export default (contract: 'ALPHA') => {
    const { Moralis } = useMoralis();
    const options = {
        chain: process.env.GATSBY_NFT_CHAIN,
        contractAddress: contracts[contract].contractAddress,
        abi: JSON.parse(contracts[contract].abi as string),
    };

    const runFunction = async (
        functionName: string,
        params: any,
        msgValue?: string
    ) => {
        const completeOptions = {
            ...options,
            functionName,
            params,
            // msgValue,
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return Moralis.executeFunction(completeOptions);
    };
    return {
        runFunction,
    };
};
