// @ts-ignore
import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    AspectRatio,
    Box,
    Button,
    Flex,
    Image,
    ScaleFade,
    Text,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import { Link, navigate } from 'gatsby';
import G3LogoDark from '../../assets/g3-dark-logo.svg';
import G3LogoLight from '../../assets/g3-light-logo.svg';
import OpenSeaLogo from '../../assets/opensea-logo.svg';

import G3ALPHAWebM from '../../assets/G3-ALPHA.webm';
import G3ALPHAPNG from '../../assets/G3-ALPHA.png';
import Layout from '../components/base/Layout';
import useContract from '../hooks/useContract';
import Container from '../components/base/Container';
import { useMoralis } from 'react-moralis';
import { BsCheckLg } from 'react-icons/bs';
import { BooleanParam, useQueryParams } from 'use-query-params';
import { TiDeleteOutline } from 'react-icons/ti';

export const QUERY_PARAM_ACCESS_DENIED = 'accessDenied';
export const QUERY_PARAM_NOT_AUTHENTICATED = 'notAuthenticated';
export default () => {
    const [queryParams] = useQueryParams({
        [QUERY_PARAM_ACCESS_DENIED]: BooleanParam,
        [QUERY_PARAM_NOT_AUTHENTICATED]: BooleanParam,
    });

    const { enableWeb3, isAuthenticated, user, isWeb3Enabled } = useMoralis();
    const [isMobile] = useMediaQuery('(max-width: 768px)');

    const { runFunction } = useContract('ALPHA');

    const logoImage = useColorModeValue(G3LogoLight, G3LogoDark);

    const [loading, setLoading] = useState(false);

    const [minting, setMinting] = useState(false);
    const [minted, setMinted] = useState(
        null as null | { contractAddress: string; tokenId: number }
    );

    const [mintingTransaction, setMintingTransaction] = useState(null as any);

    const [isAlphaListed, setIsAlphaListed] = useState(
        null as null | false | true
    );

    const onClickMint = async () => {
        if (!isAuthenticated) return navigate('/connect');
        if (!isWeb3Enabled) {
            await enableWeb3();
        }

        const { Web3Client } = require('../../api/Web3');

        const Web3 = Web3Client(process.env.GATSBY_NFT_CHAIN, 'http');

        // console.log('request start');
        // const response = await Web3.alchemy.getTransactionReceipts({
        //     blockHash:
        //         '0x9e0afbcac073989ccccf26bf1608d83f58e8c400e6ca3294487f7877aa93d196',
        // });
        //
        // console.log('response', response);
        console.log('Web3', Web3);

        const ethAddress = user?.get('ethAddress');

        try {
            const isAlphaListResponse = await runFunction('alphaList', {
                '': ethAddress,
            });

            // @ts-ignore
            setIsAlphaListed(isAlphaListResponse);
            if (isAlphaListResponse) {
                setMinting(true);
                const transaction = await runFunction('alphaListMint', {
                    amount: 1,
                });

                console.log('transaction', transaction);
                setMintingTransaction(transaction);

                // @ts-ignore
                const waitResponse = await transaction.wait(3);
                setMinting(false);
                console.log('waitResponse', waitResponse);

                const txEvent = waitResponse?.events?.[0];

                if (txEvent) {
                    const mintedData = {
                        contractAddress: txEvent?.address as string,
                        tokenId: txEvent?.args?.tokenId?.toString(),
                    };

                    console.log('mintedData', mintedData);
                    setMinted(mintedData);

                    navigate(
                        `/mint-complete/${process.env.GATSBY_NFT_CHAIN}/${mintedData.contractAddress}/${mintedData.tokenId}`
                    );
                }
            }
        } catch (e) {
            setMinting(false);
        }
    };

    console.log('minted', minted);

    const FAQ = [
        {
            q: 'What are the benefits of holding an ALPHA Pass?',
            a: `By holding an ALPHA pass you can build the next evolution of e-commerce with us and be rewarded for it. 
<br /><br /><u>Current holder benefits:</u><br />
<ul style="padding: 0 1rem;">
<li>Early access to all new features.</li>
<li>Vote on new features and platform enhancements.</li>
<li>Free allow list to future Genv3rse and Creator NFTs.</li>
<li>Direct access to the Genv3rse team.</li>
<li>Join Genv3rse backstage at future events.</li>
<li>Genv3rse products and merchandise.</li>
<li>Holders-only Discord channel and AMAs.</li>
</ul>`,
        },
        {
            q: 'What is Gen3vrse?',
            a: `A platform to help you build a Web3 business.
We’re providing a complete solution for creators, artists, entrepreneurs, and brands to make the most of Blockchain technology. 
<br /><br /><u>We’re about:</u><br />
<ul style="padding: 0 1rem;">
<li>Building deeper relationships between creators/brands and their audiences.</li>
<li>Innovating eCommerce.</li>
<li>Shared ownership.</li>
<li>No code and giving YOU the control.</li>
</ul>`,
        },
        {
            q: 'Is this an NFT project?',
            a: `No! We understand and support the power of NFTs, but Genv3rse is much more than that.<br /><br />We’re the platform that enables you to launch your own NFT collection. We believe in the technology and truly feel there’s no one out there enabling entrepreneurs, creators and artists to launch something of their own – without the need of a fully-equipped, expensive tech team. This is where Genv3rse comes in.`,
        },
        {
            q: 'Who is the team behind Genv3rse?',
            a: `We're not new to building platforms, we are the same team behind <a href="https://genflow.com" target="_blank"><u>genflow.com</u></a>, a leading creator platform we built - using which we have sold over $100M for some of the largest creators like Logan Paul, Hanna Oberg, Matt Morsia, Lilly Sabri, Jordyn Woods, and more.<br /><br />We have been executing at a high level within the creator economy since 2016 and have now observed a massive opportunity in the Web3 industry. We have the investment, an in-house team, a tech roadmap, and some of the best creators in the world, so this is just the start and we want you - our community - to be a part of it. Our plan is to launch an ALPHA version of the platform, followed by BETA and finally leading to a public launch later this year.`,
        },
        {
            q: 'Where can I find out more about the team?',
            a: 'We have a very active community on <a href="https://twitter.com/genv3rse" target="_blank"><u>Twitter</u></a> & <a href="https://discord.gg/Utt8F6266m" target="_blank"><u>Discord</u></a>. We also host weekly Twitter Spaces with other leaders in the industry, you can join us there and ask questions too.  ',
        },
        {
            q: `I missed the mint, can I still get access?`,
            a: `You can find the ALPHA Pass on <a href="https://opensea.io/collection/genv3rse-alpha-pass" target="_blank"><u>OpenSea</u></a>.`,
        },
    ];

    const [videoError, setVideoError] = useState(false);

    return (
        <Layout noContainer showConnectButton>
            <Flex
                direction="column"
                justify="center"
                align="center"
                margin="0 auto"
                minHeight="50vh"
            >
                <Box margin={['1rem 0', '0 0 4rem 0']} textAlign="center">
                    <Link
                        to="/"
                        style={{
                            textDecoration: `none`,
                        }}
                    >
                        <Image src={logoImage} width={['15rem', '25rem']} />
                    </Link>
                    <Text opacity="0.5" marginTop="0.5rem">
                        ALPHA Version
                    </Text>
                </Box>

                {queryParams?.[QUERY_PARAM_ACCESS_DENIED] && (
                    <Flex
                        maxWidth={['100%', '50vw']}
                        direction={['column', 'row']}
                        align="center"
                        margin="1rem 1rem 2rem 1rem"
                        background={useColorModeValue(
                            'rgba(0, 0, 0, 0.1)',
                            'rgba(218, 175, 255, 0.05)'
                        )}
                        backdropFilter={useColorModeValue(
                            'brightness(100%)',
                            'brightness(200%)'
                        )}
                        padding="1rem"
                        borderRadius="0.375rem"
                        textAlign={['center', 'left']}
                    >
                        <TiDeleteOutline size="2rem" />
                        <Box margin={['1rem 0 0 0', '0 0 0 1rem']}>
                            <Text fontWeight="800">{`Access Denied`}</Text>
                            <Text>
                                To access the platform you must be a holder of
                                the ALPHA Pass, which you can mint below (if you
                                made it on the ALPHA list) - otherwise you can
                                purchase one from
                                <a
                                    href="https://opensea.io/collection/genv3rse-alpha-pass"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        textDecoration: 'underline',
                                    }}
                                >
                                    <Image
                                        display="inline"
                                        verticalAlign="middle"
                                        marginLeft="0.5rem"
                                        src={OpenSeaLogo}
                                        width={6}
                                        height={6}
                                        alt={'OpenSea'}
                                        marginRight=".5rem"
                                    />
                                    OpenSea
                                </a>
                            </Text>
                        </Box>
                    </Flex>
                )}
                {queryParams?.[QUERY_PARAM_NOT_AUTHENTICATED] && (
                    <Box
                        margin="1rem 1rem 2rem 1rem"
                        textAlign="center"
                        background={useColorModeValue(
                            'rgba(0, 0, 0, 0.1)',
                            'rgba(218, 175, 255, 0.05)'
                        )}
                        backdropFilter={useColorModeValue(
                            'brightness(100%)',
                            'brightness(200%)'
                        )}
                        padding="1rem"
                        borderRadius="0.375rem"
                    >
                        {`If you already own an ALPHA pass, then you may click "Connect" to access the platform, otherwise mint a free pass below.`}
                    </Box>
                )}

                <Container extraLargeContainer>
                    <Flex direction={['column', 'row']}>
                        <Flex flex={0.5}>
                            <AspectRatio
                                maxW="100%"
                                ratio={1}
                                w="100%"
                                overflow="hidden"
                            >
                                <ScaleFade
                                    in={!loading}
                                    style={{ height: '100%' }}
                                >
                                    {videoError || isMobile ? (
                                        <Image
                                            height={'100%'}
                                            width={'100%'}
                                            src={G3ALPHAPNG}
                                        />
                                    ) : (
                                        <video
                                            height={'100%'}
                                            width={'100%'}
                                            controls={false}
                                            autoPlay
                                            muted
                                            loop
                                            onLoadedData={() =>
                                                setLoading(false)
                                            }
                                            onError={() => setVideoError(true)}
                                            playsInline
                                        >
                                            <source
                                                src={G3ALPHAWebM}
                                                type="video/webm"
                                            />
                                        </video>
                                    )}
                                </ScaleFade>
                            </AspectRatio>
                        </Flex>
                        <Box flex={0.5} padding={['0 1rem', 0]}>
                            <Text
                                fontSize="2rem"
                                fontWeight="800"
                                textAlign={['center', 'left']}
                            >
                                ALPHA Pass
                            </Text>
                            <Box
                                marginTop="1rem"
                                display="table"
                                padding="1rem"
                                background={useColorModeValue(
                                    'rgba(0, 0, 0, 0.1)',
                                    'rgba(218, 175, 255, 0.05)'
                                )}
                                backdropFilter={useColorModeValue(
                                    'brightness(100%)',
                                    'brightness(200%)'
                                )}
                                borderRadius="0.375rem"
                            >
                                <Text
                                    fontSize="1rem"
                                    opacity={useColorModeValue('0.7', '0.5')}
                                >
                                    Price
                                </Text>
                                <Text
                                    fontSize="2rem"
                                    fontWeight="800"
                                >{`0 ETH | $0 | £0`}</Text>
                                <Text fontSize="1rem" margin="1rem 0">
                                    Requirement: You must be ALPHA listed to
                                    mint right now.
                                </Text>
                                <Flex
                                    align={['left', 'center']}
                                    direction={['column', 'row']}
                                >
                                    <Button
                                        disabled={
                                            isAlphaListed === false ||
                                            !!minted ||
                                            minting
                                        }
                                        isLoading={minting}
                                        loadingText={
                                            mintingTransaction
                                                ? 'Waiting for blockchain...'
                                                : 'Minting...'
                                        }
                                        bg={minted ? 'secondary' : 'primary'}
                                        color={minted ? 'black' : 'white'}
                                        onClick={onClickMint}
                                        {...(minted
                                            ? {
                                                  rightIcon: (
                                                      <BsCheckLg
                                                          color={'black'}
                                                      />
                                                  ),
                                              }
                                            : {})}
                                    >
                                        {minted
                                            ? `Mint Completed!`
                                            : minting && !mintingTransaction
                                            ? 'Confirm Transaction'
                                            : `Mint`}
                                    </Button>
                                    <Text margin={['1rem 0 0 0', '0 0 0 1rem']}>
                                        (For FREE + gas)
                                    </Text>
                                </Flex>
                                {minted && (
                                    <Box marginTop="1rem">
                                        <Text>
                                            Once you have started the mint
                                            process, check your wallet to see
                                            when it completes. Once completed
                                            you will see the NFT in your wallet.
                                        </Text>
                                        <Text marginTop="1rem">
                                            To gain access to the platform,
                                            check the announcements in{' '}
                                            <a
                                                href="https://discord.gg/Utt8F6266m"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <u>Discord</u>
                                            </a>
                                            .
                                        </Text>
                                    </Box>
                                )}
                                {isAlphaListed === false && (
                                    <Text
                                        color="primary"
                                        fontWeight="800"
                                        marginTop="1rem"
                                    >
                                        You cannot mint right now as you are not
                                        ALPHA listed.
                                    </Text>
                                )}
                            </Box>
                            <Accordion
                                allowToggle
                                marginTop="2rem"
                                defaultIndex={0}
                            >
                                {FAQ?.map(
                                    ({ q, a }: { q: string; a: string }) => (
                                        <AccordionItem
                                            borderColor={useColorModeValue(
                                                'rgba(0, 0, 0, 0.1)',
                                                'rgba(255, 255, 255, 0.1)'
                                            )}
                                        >
                                            <AccordionButton
                                                background={useColorModeValue(
                                                    'rgba(0, 0, 0, 0.1)',
                                                    'transparent'
                                                )}
                                            >
                                                <Text
                                                    fontSize={[
                                                        '1rem',
                                                        '1.2rem',
                                                    ]}
                                                    flex="1"
                                                    textAlign="left"
                                                    color={useColorModeValue(
                                                        'black',
                                                        'white'
                                                    )}
                                                >
                                                    {q}
                                                </Text>
                                                <AccordionIcon
                                                    color={useColorModeValue(
                                                        'black',
                                                        'white'
                                                    )}
                                                />
                                            </AccordionButton>
                                            <AccordionPanel>
                                                <Box
                                                    dangerouslySetInnerHTML={{
                                                        __html: a,
                                                    }}
                                                />
                                            </AccordionPanel>
                                        </AccordionItem>
                                    )
                                )}
                            </Accordion>
                        </Box>
                    </Flex>
                </Container>
            </Flex>
        </Layout>
    );
};
